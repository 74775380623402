var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pr-8" },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("p", { staticClass: "d-inline font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.address.primary_contact.address)),
            _c("br"),
            _vm._v(
              " " +
                _vm._s(_vm.address.primary_contact.city) +
                ", " +
                _vm._s(_vm.address.primary_contact.state) +
                " " +
                _vm._s(_vm.address.primary_contact.zip) +
                " "
            )
          ]),
          _c("v-spacer"),
          _c("div", [_vm._t("indicators")], 2)
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-3", class: _vm.address.color }),
      _c("div", { staticClass: "d-flex mb-1" }, [
        _c("span", { staticClass: "d-inline mr-3" }, [
          _vm._v("Move-In:"),
          _c("strong", [_vm._v(" " + _vm._s(_vm.address.move_in_date))])
        ]),
        _c("span", { staticClass: "d-inline mr-3" }, [
          _vm._v("Move-Out:"),
          _c("strong", [_vm._v(" " + _vm._s(_vm.address.move_out_date))])
        ])
      ]),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "v-chip",
            {
              staticClass: "accent--text text--darken-2 text-subtitle-2",
              attrs: { label: "", color: "grey lighten-2" }
            },
            [_vm._v(_vm._s(_vm.address.date_description) + " ")]
          ),
          _c(
            "v-chip",
            {
              staticClass:
                "ml-3 text-subtitle-2 font-weight-bold text-uppercase",
              attrs: { color: _vm.address.durationLabel }
            },
            [_vm._v(_vm._s(_vm.address.duration))]
          )
        ],
        1
      ),
      _c("v-divider", { class: _vm.address.color + " darken-2 my-2" }),
      _c("h4", [_vm._v("Reason for leaving")]),
      _vm._v(" " + _vm._s(_vm.address.reason_for_moving) + " "),
      _c("v-divider", { class: _vm.address.color + " darken-2 my-2" }),
      _c("h4", [_vm._v("Reference(s)")]),
      _vm._l(_vm.address.references, function(reference, index) {
        return [
          _c(
            "div",
            { key: index },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("span", [
                    _vm._v(" " + _vm._s(reference.contact.name) + " ")
                  ]),
                  _c(
                    "v-icon",
                    { class: "ml-2 " + _vm.address.color + "--text" },
                    [_vm._v("mdi-check-circle")]
                  )
                ],
                1
              ),
              _c("span", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(reference.contact.full_address))
              ]),
              _c("v-divider", { staticClass: "my-1" }),
              _c(
                "span",
                { staticClass: "font-weight-light" },
                [
                  _c(
                    "v-icon",
                    { class: "mr-1 " + _vm.address.color + "--text" },
                    [_vm._v("mdi-email")]
                  ),
                  _vm._v(_vm._s(reference.contact.email)),
                  _c("br"),
                  _c(
                    "v-icon",
                    { class: " mr-1 " + _vm.address.color + "--text" },
                    [_vm._v("mdi-phone")]
                  ),
                  _vm._v(_vm._s(reference.contact.phone) + " ")
                ],
                1
              ),
              _c("v-divider", { staticClass: "my-1" }),
              _c(
                "span",
                { staticClass: "font-weight-light" },
                [
                  _c("v-icon", { class: _vm.address.color + "--text mr-1" }, [
                    _vm._v("mdi-calendar")
                  ]),
                  _vm._v(_vm._s(reference.completed_at) + " ")
                ],
                1
              )
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }