<template>
    <div class="pr-8">
        <div class="d-flex">
            <p class="d-inline font-weight-bold">
                {{ address.primary_contact.address }}<br />
                {{ address.primary_contact.city }},
                {{ address.primary_contact.state }}
                {{ address.primary_contact.zip }}
            </p>
            <v-spacer></v-spacer>
            <div>
                <slot name="indicators"></slot>
            </div>
        </div>
        <v-divider class="my-3" :class="address.color"></v-divider>
        <div class="d-flex mb-1">
            <span class="d-inline mr-3"
                >Move-In:<strong> {{ address.move_in_date }}</strong></span
            >
            <span class="d-inline mr-3"
                >Move-Out:<strong> {{ address.move_out_date }}</strong></span
            >
        </div>
        <div class="d-flex">
            <v-chip
                label
                color="grey lighten-2"
                class="accent--text text--darken-2 text-subtitle-2"
                >{{ address.date_description }}
            </v-chip>
            <v-chip
                class="ml-3 text-subtitle-2 font-weight-bold text-uppercase"
                :color="address.durationLabel"
                >{{ address.duration }}</v-chip
            >
        </div>
        <v-divider :class="`${address.color} darken-2 my-2`"></v-divider>
        <h4>Reason for leaving</h4>
        {{ address.reason_for_moving }}
        <v-divider :class="`${address.color} darken-2 my-2`"></v-divider>
        <h4>Reference(s)</h4>
        <template v-for="(reference, index) in address.references">
            <div :key="index">
                <div class="d-flex">
                    <span>
                        {{ reference.contact.name }}
                    </span>
                    <v-icon :class="`ml-2 ${address.color}--text`"
                        >mdi-check-circle</v-icon
                    >
                </div>
                <span class="mb-2">{{ reference.contact.full_address }}</span>
                <v-divider class="my-1"></v-divider>
                <span class="font-weight-light">
                    <v-icon :class="`mr-1 ${address.color}--text`"
                        >mdi-email</v-icon
                    >{{ reference.contact.email }}<br /><v-icon
                        :class="` mr-1 ${address.color}--text`"
                        >mdi-phone</v-icon
                    >{{ reference.contact.phone }}
                </span>
                <v-divider class="my-1"></v-divider>
                <span class="font-weight-light">
                    <v-icon :class="`${address.color}--text mr-1`"
                        >mdi-calendar</v-icon
                    >{{ reference.completed_at }}
                </span>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: "rental-history-address",
    components: {},
    props: {
        address: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>